import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Web & Mobile Development
                                </Link>
                            </h3>
                            <p>Got a tech idea? Looking to simplfy a transactional process or develop a HR interface for your business? theParse can develop a custom software solution for your business at affordable rates.</p>

                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/service-details-api">
                                Service Details Unified APIs
                                </Link>
                            </h3>

                            <p>Looking to simplfy or bring about transparency to a business process? theParse can develop a IoT solution with advanced analytics capabilities for monitoring your entire business process in real-time.</p>
                            
                            <Link className="view-details-btn" to="/service-details-api">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details-ds">
                                IoT & Advanced Analytics
                                </Link>
                            </h3>

                            <p>The eCommerce market still has a lot of potential growth. Our team have developed an conversational AI eCommerce solution that aims to understand your area of trade.</p>
                            
                            <Link className="view-details-btn" to="/service-details-ds">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                eCommerce Platforms as a Service
                                </Link>
                            </h3>

                            <p>The eCommerce market still has a lot of potential growth. Our team have developed an conversational AI eCommerce solution that aims to understand your area of trade.</p>
                           
                            <Link className="view-details-btn" to="#">
                                Comming Soon
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                Analytics
                                </Link>
                            </h3>

                            <p>Real-time monitoring and alerting is at the core of daily business performance. Are you looking for a data-driven solution to bring transparency to your business process?</p>
                            
                            <Link className="view-details-btn" to="#">
                                Comming Soon
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                Data Science and Research
                                </Link>
                            </h3>

                            <p>With a passion for technology and all things IoT, our team of software engineers have crafted a process for turning technology and research ideas into reality.Talk to us today for next great idea in tech!</p>
                            
                            <Link className="view-details-btn" to="#">
                                Comming Soon
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne